<template>
  <EndTestBackground :testType="1">
    <div class="end-test">
      <div>
        <span class="green">{{ userInfo.studentName }}同学</span
        >，恭喜你完成中考英语冲刺战备体检！
      </div>
      <div>
        本次体检得分：<span class="green-class">{{ result.testScore }}分</span>获得 测评答题
        <pybWord :pybNum="result.answerRewardNumber"></pybWord> 关卡奖励
        <pybWord :pybNum="result.sprintRewardNumber"></pybWord>，已纳入你的后勤补给！
      </div>
      <div>
        定级冲刺关卡：<span class="green">{{
          ratingModule(result.gradingSprintId)
        }}</span>
      </div>
      <div>
        你的体检排名： 超过<span class="green"
          >{{ result.classRanking | turnPercentage }}%</span>全班已测试人数，超过<span class="green">{{ result.schoolRanking | turnPercentage }}%</span
        >全校已测试人数，超过<span class="green">{{ result.districtRanking | turnPercentage }}%</span>全区已测试人数
      </div>
      <div>
        <span class="iconfont icon-tishi yellow"></span>
        当前班级测试人数
        <span class="yellow ">{{ result.classFinishNumber }}</span
        >人，全校完成测试人数
        <span class="yellow">{{ result.schoolFinishNumber }}</span
        >人，全区完成测试人数
        <span class="yellow">{{ result.districtFinishNumber }}</span
        >人
      </div>
      <div class="end-button">
        <span>
          <GlobalButton
            buttonType="round"
            buttonText="查看报告"
            @afterClick="viewReport"
          ></GlobalButton>
        </span>
        <span @click="continueSprint">回大本营</span>
      </div>
    </div>
  </EndTestBackground>
</template>

<script>
import GlobalButton from "@/components/global-button/global-button-enter.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
import EndTestBackground from "@/components/backgroung/end-test-background.vue";
import { getEngFinishProgress } from "../../api/english-examination";
import { mapState } from "vuex";
export default {
  data() {
    return {
      result: "",
    };
  },
  components: {
    GlobalButton,
    pybWord,
    EndTestBackground,
  },
  
  mounted() {
    this.finishPage();
  },
  computed:{
    ...mapState(["userInfo"]),
  },
  methods: {
    finishPage() {
      const parmeter = { recordExamId: this.$route.query.studentExamId };
      getEngFinishProgress(parmeter).then((res) => {
        console.log("完成页", res);
        this.result = res.data.result;
      });
    },
    continueSprint() {
      this.$router.replace({
        name: "HomeIndex",
      });
    },
    viewReport() {
      this.$router.replace({
        name: "ExaminationReport",
        query: {
          // module: 4, //4为报告页面 ，不带人物背景
          studentExamId: this.$route.query.studentExamId, 
          sprintId: this.$route.query.sprintId,
        },
      });
    },
    ratingModule(gradingSprintId) {
      console.log("gradingSprintId", gradingSprintId);
      if (gradingSprintId == 2) {
        return "扫雷尖兵(小模块)";
      }
      if (gradingSprintId == 4) {
        return "沙漠行动(大模块)";
      }
      if (gradingSprintId == 7) {
        return "抢滩登陆(大模拟)";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.end-test {
  & > div {
    padding: 0.7rem 0;
  }
  .green {
    color: $text-green;
    font-weight: bold;
    padding: 0 6px;
  }
  .green-class {
    color: $text-green;
    font-weight: bold;
    padding-right: 6px;
  }
  .end-button {
    padding: 2rem 0;
    & > span {
      padding: 1rem 1.5rem;
    }
    & > span:nth-child(2) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .yellow {
      color: #FFC13B;
      font-weight: bold;
  }
}
</style>
